import * as React from 'react';
import {graphql} from 'gatsby';
import {MDXRenderer} from 'gatsby-plugin-mdx';
import {GatsbyImage, getImage} from 'gatsby-plugin-image';
import Layout from '../../components/layout';
import PropTypes from 'prop-types';
import {SeeOnGithub} from '../../components/literary-components';

import 'katex/dist/katex.min.css';
import '../../css/blog.css';


const BlogPost = ({data}) => {
  const element = data.mdx;
  const front = element.frontmatter;
  let heroImage = null;
  if (front.heroImage) {
    const heroImageElement = getImage(front.heroImage);
    heroImage = <GatsbyImage image={heroImageElement} alt={front.heroImageAlt} className="hero-image" />;
  }
  console.log(front);
  const git = front.github ? <SeeOnGithub link={front.github} /> : null;
  return (
    <Layout pageTitle={front.title}>
      <div className='blog-post-header'>
        <h2>{front.title}</h2>
        {git}
      </div>
      {heroImage}
      <div className="blog-post">
        <MDXRenderer localImages={front.localImages}>
          {element.body}
        </MDXRenderer>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($id: String) {
    mdx(id: {eq: $id}) {
      frontmatter {
        title
        github
        heroImage {
          childImageSharp {
            gatsbyImageData
          }
        }
        heroImageAlt
        localImages {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      body
    }
  }
`;

BlogPost.propTypes = {
  data: PropTypes.element.isRequired,
};

export default BlogPost;
